import {Directive, ElementRef, EventEmitter, HostListener, Input, Output} from '@angular/core';

@Directive({
  selector: '[msToggle]',
  exportAs: 'toggle',
  standalone: true,
})
export class ToggleDirective {
  @Input('msToggle')
  active: boolean | '' = false;

  @Input()
  toggleOutside = true;

  @Output()
  toggled = new EventEmitter<boolean>();

  get inactive() {
    return !this.active;
  }

  constructor(private readonly eRef: ElementRef) {}

  @HostListener('click')
  onClick() {
    this.toggle();
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: Event) {
    const insideClicked = this.eRef.nativeElement.contains(event.target);
    if (this.toggleOutside && this.active && !insideClicked) {
      this.toggle();
    }
  }

  private toggle() {
    this.active = !this.active;
    this.toggled.emit(this.active);
  }
}
